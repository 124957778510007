.App {
  text-align: center;
  color: #fff5e1;
  background-color: #011107;
  min-height: 100vh;
}

.App a,
.App a:active,
.App a:focus,
.App a:visited {
  color: #f8b020;
}

.App a:hover {
  color: #fff5e1;
}

.App h1 {
  font-size: 16pt;
  margin-bottom: 20px;
  opacity: 0.8;
}

.App h2 {
  font-size: 20pt;
  font-style: italic;
}

.App ul {
  margin: 40px 0 0;
  padding: 0;
}

.App li {
  margin: 20px;
  list-style-type: none;
}

.App p {
  margin: 3px;
}

.App main {
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.App nav {
  background-color: #032910;
  padding: 20px 0;
}

.App nav a {
  text-transform: uppercase;
}

.App footer {
  font-size: 10pt;
  opacity: 0.7;
  padding-bottom: 10px;
}
